import { axiosApi } from "../configs/apiConfig";
import { AppDependencies } from "./appDependencies";
import { toast } from "react-hot-toast";
import { ICreateProjectDto } from "../dtos/projects/ICreateProjectDto";
import { ICreateIssueDto } from "../dtos/issues/ICreateIssueDto";
import { IProjectDto } from "../dtos/projects/IProjectDto";
import { IIssueDto } from "../dtos/issues/IIssueDto";
import { isStringEmptyOrWhitespace } from "../utility/stringUtils/stringUtils";
import { IIssueDurationDto } from "../dtos/issues/IIssueDurationDto";
import { IIssueEmployeeDto } from "../dtos/issueEmployees/IIssueEmployeeDto";

interface issueEmployeeDto {
  employeeDescription?: string;
  employeeId: string;
  estimateDuration: number;
  estimateDurationStr?: string;
  issueId: string;
}

export class IssueService {
  async GetAll(searchText?: string) {
    let url = `/issues`;
    if (searchText && searchText !== "") {
      url += `/${searchText}`;
    }

    const response = await axiosApi.get(url);
    return response.data;
  }

  async GetAllByProject(projectId: string, searchText?: string) {
    const searchTextParam = `?searchText=${searchText}`;
    const url = `/issues/by-project/${projectId}${
      isStringEmptyOrWhitespace(searchText) ? "" : searchTextParam
    }`;
    const response = await axiosApi.get(url);
    return response.data;
  }

  async GetAllByProjectAndUser(projectId: string, searchText?: string) {
    const searchTextParam = `?searchText=${searchText}`;
    const url = `/issues/by-project-and-user/${projectId}${
      isStringEmptyOrWhitespace(searchText) ? "" : searchTextParam
    }`;
    const response = await axiosApi.get(url);
    return response.data;
  }

  async Create(item: ICreateIssueDto): Promise<boolean> {
    try {
      const response = await AppDependencies.Axios.post("/issues", item, {});
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status !== 200) {
        throw `requestError. status code ${response.status}`;
      }

      toast.success("Created successfully!");
      return true;
    } catch (e) {
      if (process.env.MODE === "debug") {
        console.log(e);
      }

      toast.error("Create failure!");
    }
    return false;
  }

  async Update(item: IIssueDto): Promise<boolean> {
    try {
      const response = await AppDependencies.Axios.put("/issues", item, {});
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status === 200) {
        toast.success("Updated successfully!");
        return true;
      } else {
        throw `requestError. status code ${response.status}`;
      }
    } catch (e) {
      if (process.env.MODE === "debug") {
        console.log(e);
      }

      toast.error("Update failure!");
    }
    return false;
  }

  async Delete(id: string) {
    try {
      const response = await AppDependencies.Axios.delete(`/issues/${id}`);
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status === 200) {
        toast.success("Deleted successfully!");
        return true;
      } else {
        throw `requestError. status code ${response.status}`;
      }
    } catch (e) {
      if (process.env.MODE === "debug") {
        console.log(e);
      }

      toast.error("Delete failure!");
    }
    return false;
  }

  async GetOne(id: string): Promise<IIssueDto> {
    const url = `/issues/one/${id}`;
    const response = await AppDependencies.Axios.get(url);
    return response.data;
  }

  async GetIssueDurations(
    issueId: string | undefined,
    administrationTimeStatus?: boolean | null
  ): Promise<IIssueDurationDto> {
    if (!issueId) {
      return {
        timeEstimate: 0,
        timeSpent: 0,
        timeRemains: 0,
      };
    }
    const response = await axiosApi.get(`/issues/durations/${issueId}`, {
      params: {
        status: administrationTimeStatus,
      },
    });
    return response.data;
  }

  async FillIssueEmployees(
    item: issueEmployeeDto[],
    projectId: string
  ): Promise<boolean> {
    try {
      const response = await AppDependencies.Axios.post(
        `/issues/matrix/${projectId}`,
        item,
        {}
      );
      if (process.env.MODE === "debug") {
        console.log(response);
      }

      if (response.status !== 200) {
        throw `requestError. status code ${response.status}`;
      }

      toast.success("Filled successfully!");
      return true;
    } catch (e) {
      if (process.env.MODE === "debug") {
        console.log(e);
      }

      toast.error("Fill failure!");
    }
    return false;
  }

  async UpdateIssueSubject(data: {
    catalogId: string;
    issueSubject: string;
  }): Promise<IIssueDto> {
    const url = `/issues/update-subject`;
    const response = await AppDependencies.Axios.post(url, data, {});
    return response.data;
  }
}
